import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import {storedMortgagorOptions, storedMortgageeOptions} from '../../utility/Data'

export default class DropdownInput extends Component {
  state = {
    searchQuery: this.props.value,
    selected: false
   };


    handleSearchChange = (e, { searchQuery }) => { 
        //console.log(searchQuery)
        // const setOpen = searchQuery.length >= 1

        this.setState({ searchQuery, value: searchQuery })
        //this.props.onChange(null, {name: this.props.name, value:searchQuery})
    }

    handleOnChange = (e, { value }) => {
        //console.log(value)

        this.setState({searchQuery: value})
        //this.props.onChange(null, {name: this.props.name, value})
    }
 
    handleOnBlur = (e, {searchQuery}) => {
      //console.log(searchQuery)

//      this.setState({searchQuery})
      this.props.onChange(null, {name: this.props.name, value: searchQuery})
    }

    render() {
      const { searchQuery, value } = this.state

      const { name, label } = this.props

      const isMortgagor = name === 'mortgagor'

      return (
        <div className='ui small left labeled input cema-form-input'>
        <div className='ui label label left'>{label}</div>
        <Dropdown
            fluid
            onChange={this.handleOnChange}
            onSearchChange={this.handleSearchChange}
            onBlur={this.handleOnBlur}
            options={isMortgagor ? storedMortgagorOptions : storedMortgageeOptions}
            name={name}
            search
            searchQuery={searchQuery}
            selection
            value={value}
            noResultsMessage={null}
            selectOnBlur={true}
            selectOnNavigation={false}
            minCharacters={1}
            disabled={this.props.disabled}
        />
        </div>
      )
    }
  }