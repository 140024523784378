import React from 'react'
import {Select} from 'semantic-ui-react'

const SelectInput = ({ ...inputProps }) => {

  return <div className='ui small left labeled input cema-form-input'>
      <div className='ui label label left'>{inputProps.label}</div>
        <Select multiple={inputProps.multiple} placeholder={inputProps.placeholder} options={inputProps.options} value={inputProps.value} onChange={inputProps.onChange} disabled={inputProps.disabled} />
      </div>
}

export default SelectInput