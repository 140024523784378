import { isEmpty } from './Helper';

export const SiteID = process.env.REACT_APP_SITE_ID

export const NotAvailable = 'N/A'
export const OverrideErrorMessage = 'Field is required. Submit again to override.'

export const ZolaSearchURL = process.env.REACT_APP_ZOLA_SEARCH_URL // param = q

export const BuilderPathURL = process.env.REACT_APP_SERVER_URL + "/builder"
export const LoanOfficerPathURL = process.env.REACT_APP_SERVER_URL + "/loanofficer"
export const LenderPathURL = process.env.REACT_APP_SERVER_URL + "/lender"
export const AttorneyPathURL = process.env.REACT_APP_SERVER_URL + "/attorney"
export const CemaAPIPathURL = process.env.REACT_APP_SERVER_URL + "/api"
export const MortgageePathURL = process.env.REACT_APP_SERVER_URL + "/mortgagee"
export const BankPathURL = process.env.REACT_APP_SERVER_URL + "/bank"


export const APIPathURL = process.env.REACT_APP_API_SERVER_URL + "/api"
export const OtherPropertyPathURL = process.env.REACT_APP_API_SERVER_URL + "/otherproperty"
export const BorrowerPathURL = process.env.REACT_APP_API_SERVER_URL + "/borrower"

// export const SSO_URL = process.env.REACT_APP_SSO_AUTHORITY_URL
export const ManageProfileURL = process.env.REACT_APP_SSO_AUTHORITY_URL+"/Identity/Account/Manage"

export const getPropertyAddress = (property) => {
  if (!property || isEmpty(property)) return ''
  return property.displayAddress
  //return `${property.address} ${boroLookup[property.borocode].toUpperCase()}, NY ${property.zipcode}`
}

export const getBorrowerName = (borrower) => {
  if (!borrower || isEmpty(borrower)) return ''
  return `${borrower.name}`
}

export const getReferralName = (referral) => {
  if (!referral || isEmpty(referral)) return ''
  return `${referral.name}`
}


export const boroLookup = {
  1: 'Manhattan',
  2: 'Bronx',
  3: 'Brooklyn',
  4: 'Queens',
  5: 'Staten Island',
};

export const PlutoCounties = ['Kings County', 'Bronx County', 'New York County', 'Queens County', 'Richmond County']