import { getUser } from "./Auth"

let loadingData = false

export async function makeRequestWithCallback(url, callback = null, includeToken = true) {
    const options = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //signal: abortController?.signal
    }
    if (includeToken) {
      const token = (await getUser()).access_token
  
      options.headers = {
        'Authorization': 'Bearer ' + token
      }
    }
  
    fetch(url, options)
      .then(res => res.json())
      .then((results) => {
        if (callback) {
          callback(results)
        }
      },
      (error) => {
        console.log(error)
        if (error.name === 'AbortError') {
          callback(null)
        }
      })
  }
  
  export async function makePostRequest(url, data = {}) {
    return await makeRequest('POST', url, data)
  }
  
  export async function makePutRequest(url, data = {}) {
    return await makeRequest('PUT', url, data)
  }
  
  export async function makeGetRequest(url) {
    return await makeRequest('GET', url)
  }
  
  export async function makeDeleteRequest(url) {
    return await makeRequest('DELETE', url)
  }
  
  async function makeRequest(method, url, data = {}) {
    if (loadingData && method !== 'GET') {
      return
    }
    loadingData = true
  
    const token = (await getUser()).access_token
    
    const options = {
      method: method, // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',//x-www-form-urlencoded',
        'Connection': 'Keep-Alive',
        'Authorization': 'Bearer ' + token
      }
    }
    
    if (method !== 'GET' && method !== 'DELETE') {
      options.body = JSON.stringify(data) // body data type must match "Content-Type" header
    }
  
    console.log(url)
  
    const result = await fetch(url, options)
    .then(res => {
      // console.log(res)
      return res.ok ? res.json() : null
    })
    .then((result) => {
      return result
    }).catch((error) => {
      console.log(error);
      return null
    })
  
    loadingData = false
  
    console.log(result)
  
    return result
  }
  
  export function downloadFile(url) {  
    window.location.href = url
  }

  
  export async function openFile(url, doc = '') {
    //window.open(url, '_blank')
    const token = (await getUser()).access_token
  
    const options = {
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    // { return r.blob().then(blob => {
    //   return {
    //     contentType: r.headers.get("content-disposition"),
    //     raw: blob
    //   }
    // })}
  
    var result = fetch(url, options)
    .then(r => r.blob())
    .then(function(blob) {
      // console.log(d.contentType)
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.target = '_blank'
      if (doc.length > 0) {
        link.download=doc;
      }
      //
      link.click();
      setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);

      // var file = window.URL.createObjectURL(blob);
      // window.location.assign(file);
      return 1
    }).catch(error => {
      return 0
    })

    return result
  }
  
  export async function uploadFiles(url, files) {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append(file.name, file, file.name)
    })
  
    return await makeUploadRequest(url, formData)
  }
  
  async function makeUploadRequest(url, formData) {
    if (loadingData) {
      return
    }
    loadingData = true
  
    const token = (await getUser()).access_token
    
    const options = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: formData
    }
  
    console.log(url)
  
    const result = await fetch(url, options)
    .then(res => {
      return res.ok ? res.json() : null
    })
    .then((result) => {
      return result
    }).catch((error) => {
      console.log(error);
      return null
    })
  
    loadingData = false
  
    console.log(result)
  
    return result
  }
    