import { isEmpty } from './Helper'

function arrayRemove(arr, value) { return arr.filter(function(ele){ return ele !== value; });}

export const storedMortgagorOptions = []

export function addToMortgagors(mortgagor) {
  const found = storedMortgagorOptions.find(_ => _.text === mortgagor)
  if (!found) {
    storedMortgagorOptions.push({key: mortgagor, value: mortgagor, text: mortgagor})
    storedMortgagorOptions.sort((a,b) => {return a.value > b.value ? 1 : -1})
  }
}

export function removeFromMortgagors(mortgagor) {
  const found = storedMortgagorOptions.find(_ => _.text === mortgagor)
  if (found) {
    var index = storedMortgagorOptions.indexOf(found)
    if (index > -1) {
      storedMortgagorOptions.splice(index, 1)
    }
  }
}

export const storedMortgageeOptions = []
export const storedBankOptions = []

export function addToMortgagees(mortgagee) {
  const found = storedMortgageeOptions.find(_ => _.text === mortgagee)
  if (!found) {
    storedMortgageeOptions.push({key: mortgagee, value: mortgagee, text: mortgagee})
    storedMortgageeOptions.sort((a,b) => {return a.value < b.value ? 1 : -1})
  }
}

export function addToBanks(bank) {
  const found = storedBankOptions.find(_ => _.key === bank.id)
  if (!found) {
    storedBankOptions.push({key: bank.id, value: bank.id, text: bank.name})
    storedBankOptions.sort((a,b) => {return a.name > b.name ? 1 : -1})
  }
}

export const resetData = () => {
  allObjects.splice(0, allObjects.length)
  storedMortgagorOptions.splice(0, storedMortgagorOptions.length)
  storedMortgageeOptions.splice(0, storedMortgageeOptions.length)
}

export const allObjects = []

export function getObject(id, isTransfer = false) {
    return allObjects.find(o => o.id === id && isTransferType(o.type) === isTransfer)
  }

export function getObjectByStop(stop, transferStop = null) {
  return allObjects.find(o => o.stop === stop && o.transferStop === transferStop)
}
  
export function getObjects(ids) {
    return allObjects.filter(o => ids.includes(o.id))
}

export function getObjectsWhereConsolidated(id) {
    return allObjects.filter(o => o.type === StopType.Cema && o.consolidationFrom.includes(id))
}

export function getObjectWhereTransferedFrom(id, notId = null) {
    return allObjects.filter(o => !isEmpty(o.transferFrom)).find(o => o.transferFrom.id === id && o.id !== notId)
}

export function getObjectWhereTransferedTo(id) {
  return allObjects.find(o => o.transferToId === id)
}

export const StopType = {
    None: "None",
    Mortgage: "Mortgage",
    Cema: "CEMA",
    ATransfer: "Assignment Transfer",
    MTransfer: "MERS Transfer"
  }

  /** Data Helper  **/

  export function isTransferType(type) {
    return type === StopType.ATransfer || type === StopType.MTransfer
  }
  
  export function isATransferType(type) {
    return type === StopType.ATransfer
  }
  
  export function isMTransferType(type) {
    return type === StopType.MTransfer
  }
  
  export function isMortgageType(type) {
    return type === StopType.Mortgage
  }
  
  export function isCemaType(type) {
    return type === StopType.Cema
  }

  
  /** Data Objects **/
  
  export const mortgage = (type) => {
    return {
      mortgagor: '',
      mortgagee: '',
      isMers: false,
      amount: 0,
      date: null,
      isCopy: true,
      isSubLien: false,
      consolidatedToId: null,
      stop: 0,
      id: null,
      consolidatedAmount: 0,
      cemaDate: null,
      isCEMACopy: true,
      isNoNewMoney: false,
      isAmended: false,
      consolidationFrom: [],
      type: type,
      recordedDate: null,
      crfn: '',
      mortgageTaxPaid: 0,
      cemaRecordedDate: null,
      cemaCRFN: ''
    }
  }
  
  export const transfer = (type) => {
    return {
      assignee: '',
      isMers: isMTransferType(type),
      date: null,
      isCopy: isATransferType(type),
      transferFrom: {},
      type: type,
      stop: 0,
      transferStop: 0,
      id: null,
      recordedDate: null,
      crfn: '',
    }
  }
  
  /** End Data Objects **/
  
  /** Data Error Objects **/
  
  export const mortgageError = {
    mortgagor: false,
    mortgagee: false,
    amount: false,
    amountOverride: false,
    date: false,
    dateOverride: false,
    isCema: false,
    isAmended: false
  }
  
  export const cemaError = {
    mortgagor: false,
    mortgagee: false,
    amount: false,
    amountOverride: false,
    consolidatedAmount: false,
    consolidatedAmountOverride: false,
    newDate: false,
    newDateOverride: false,
    cemaDate: false,
    cemaDateOverride: false,
    consolidationFrom: false,
    isAmended: false
  }
  
  export const aTransferError = {
    assignee: false,
    date: false,
    dateOverride: false,
    transferFrom: false
  }
  
  export const mTransferError = {
    assignee: false,
    date: false,
    dateOverride: false,
    transferFrom: false,
  }
  
  /** End Data Error Objects **/

  export const cemaStatus = [
    'Not Ordered',
    'Ordered',
    'Scans Received',
    'Partially Received',
    'Partially Received / Submitted',
    'Submitted',
    'Approved',
    'Closed',
    'Archived'
  ]

  export const cemaStatusColors = [
    'cema-not-ordered-color',
    'cema-ordered-color',
    'cema-scans-receive-color',
    'cema-partial-receive-color',
    'cema-partial-submit-color',
    'cema-submit-color',
    'cema-approve-color',
    'cema-closed-color',
    'cema-archived-color'
    ]