import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './utility/CSS-Importer'

import { AuthProvider } from "oidc-react";
import { userManagerSettings } from './utility/Auth';
import { addDataIntoCache } from './utility/Helper';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Create a root

root.render(
  <BrowserRouter basename={baseUrl}>
    <AuthProvider {...userManagerSettings} onBeforeSignIn={() => addDataIntoCache('path', window.location.pathname)}>
      <App />
    </AuthProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

