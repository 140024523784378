import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { isEmpty, textForCopy, textForMers, textForAmount, textForDate } from "../../utility/Helper";
import { isCemaType } from "../../utility/Data";
import { getBorrowerName, getPropertyAddress, getReferralName } from "../../utility/Global";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const sectionTypes = [1,3,2,4]

function sectionForType(sType) {
  switch(sType) {
    case 1:
    case 3:
      return 'Mortgage'
    case 2:
    case 4:
      return 'Consolidated'
  }
}

function checklistTitleLabel(key, amendedStop, section) {
  switch (section) { 
    case 1:
      return `Mtg ${key}`
    case 2:
      return `Mtg ${key} Cons.`
    case 3:
      return `Mtg ${key} Amnd. ${amendedStop}`
    case 4:
      return `Mtg ${key} C. Amnd. ${amendedStop}`
  }
}

function assignmentForExport(assignment) {
    return {text: [ {text: `${textForCopy(assignment.isCopy)}Assignment`, bold: true},` from ${textForMers(assignment.fromMers)}${assignment.personOne}`, {text : '  >  ', bold: true}, `${textForMers(assignment.isMers)}${assignment.personTwo}${textForDate(assignment.date)}`]}
}

function allongeForExport(allonge) {
    return {text: [ {text: 'Allonge', bold: true}, ` from ${allonge.personOne}`, {text : '  >  ', bold: true}, `${allonge.personTwo}`]}
}

function noteForExport(type, note) {
    return {text: [ {text: `${type} Note`, bold: true}, ` for ${note.mortgagor}`, {text : '  >  ', bold: true}, `${note.mortgagee}${textForDate(note.date)}${textForAmount(note.amount)}`]}
}

function copyForMortgageExport(copy) {
  return {text: [ {text: `${textForCopy(copy.isCopy)}${copy.section}`, bold: true},`${textForAmount(copy.amount)}`]}
}

function copyForExport(copy) {
    return {text: [ {text: `${textForCopy(copy.isCopy)}${copy.section}`, bold: true}, ` from ${copy.mortgagor}`, {text : '  >  ', bold: true}, `${copy.mortgagee}${textForDate(copy.date)}${textForAmount(copy.amount)}`]}
}

function labelForHeader(title, text) {
    return { text: [ { text: `${title}:`, style: 'headerTitle'}, `  ${text}`] }
}

export function ChecklistExport(groupInfo, property, borrower, checklist) {
    let content = []
    if (!isEmpty(checklist)) {
        content = Object.entries(checklist).map(([key, section]) => {

          let columns = []

          sectionTypes.forEach((element, index) => {
            var sectionNotes = section.notes.filter(_ => _.sectionType === element)
            var sectionCopies = section.copies.filter(_ => _.sectionType === element)
            var sectionAllonges = section.allonges.filter(_ => _.sectionType === element)

            var largerCountArray = sectionNotes.length >= sectionCopies.length ? sectionNotes : sectionCopies


            largerCountArray.sort((a, b) => {
              if (!a.amendedStop) {
                return 1
              } else {
                if (a.amendedStop > b.amendedStop) return 1
                if (a.amendedStop < b.amendedStop) return -1
              }
            })

            largerCountArray.map((item, index) => {
              var aStop = item.amendedStop
              var note = sectionNotes.filter(_ => _.amendedStop === aStop)[0] ?? null
              var copy = sectionCopies.filter(_ => _.amendedStop === aStop)[0] ?? null

              var allonges = sectionAllonges.filter(_ => _.amendedStop === aStop) ?? []

              var sectionType = note ? note.sectionType : copy ? copy.sectionType : 0
              var amendedStop = note ? note.amendedStop : copy ? copy.amendedStop : 0

              var isMortgage = (sectionType === 1 || sectionType === 3)

              let noteItem

              if (note && copy) {
                let mAllongeList = allonges.map((allonge) => {
                  return allongeForExport(allonge)
                })  

                noteItem = {
                  ul: [
                    noteForExport(!isMortgage ? 'Consolidated' : '', note),
                    {
                      ul: mAllongeList
                    },
                    isMortgage ? copyForMortgageExport(copy) : copyForExport(copy)
                  ]
                }
      
                
              } else if (!note && copy) {
                noteItem = {
                  ul: [
                    (sectionType === 1 || sectionType === 3) ? copyForMortgageExport(copy) : copyForExport(copy)
                  ]
                }          
              }

              columns.push({columns: [
                {
                  text: checklistTitleLabel(key, amendedStop, sectionType),
                  width: '20%'
                },
                {
                  ul: [noteItem],
                  width: '*'
                }
              ]})
            })

          })

          const assignments = section.assignments        
  
  
          let assignmentList = {
            ul: assignments.map((assignment) => {
              return assignmentForExport(assignment)
            })
          }


          columns.push({columns: [
            {
              text: '',
              width: '20%'
            },
            {
              ul: [assignmentList],
              width: '*'
            }
          ]})
        
          return [columns, '\n']
  
        })
      }

      let docDefinition = {
        content: [
            { stack: [
                labelForHeader('Borrower', getBorrowerName(borrower)),
                labelForHeader('Property', `${getPropertyAddress(property)} ${property ? `(${property?.block} / ${property?.lot})` : ''}`),
                labelForHeader('Loan Officer', getReferralName(groupInfo.loanOfficer))
                ],
                style: 'header'
            },
            content
          ],
        defaultStyle: {
          fontSize: 11,
          lineHeight: 1.1
        },
        styles: {
            header: {
                margin: [0, 0, 0, 25]
            },
            headerTitle: {
                fontSize: 12,
                bold: true
            }
        }
      }

      pdfMake.createPdf(docDefinition).open();
}