import React from 'react';

import {Message, Container, Transition} from 'semantic-ui-react'

export const RenderLoadingMessage = () => (
    <p><em>Loading...</em></p>
  )
  
export const RenderErrorMessage = () => (
    <p><em>Error Loading Content</em></p>
  )

   
export const ErrorMessage = ({message}) => (
    <Message negative>
      <Message.Header>{message}</Message.Header>
    </Message>
  )

export const WYSIWYGLabel = ({className, message}) => (
  <span className={className} style={{whiteSpace: 'pre', pointerEvents: 'none'}}>{message}</span>
)

export const TransitionContainer = ({className, style, fluid = true, children}) => (
    <Container className={className} fluid={fluid} style={style}>
        <Transition 
        animation='fade'
        reactKey={className}
        transitionOnMount={true}
        visible={true}
        duration={500}
        >
            {children}
        </Transition>
    </Container>
)