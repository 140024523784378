import React, {Component} from 'react';

import {Accordion} from 'semantic-ui-react'

export default class ContainedAccordion extends Component {
    constructor(props) {
        super(props)
        this.state = {...props, activeIndex: 0}
      }

      static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState) {
          return ({...nextProps})
        }
      }

    handleClick = (e, titleProps) => {
        const cNames = e.target.className.split(' ')
        if (!cNames || !cNames.includes('dropdown')) {
            return
        }
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const {titleChildren, contentChildren, activeIndex} = this.state
      
        return (
            <Accordion>
            <Accordion.Title
            className='info-title'
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
            >
            {/* {contentChildren ? (
                <Icon name='dropdown' />
            ) : null } */}
            {titleChildren}
            </Accordion.Title>
            <Accordion.Content className='info-content' active={activeIndex === 0}>
            {contentChildren}
            </Accordion.Content>
        </Accordion>
        )
    }
}