import React from 'react';
import NavMenu from './NavMenu';

function Layout({children, user, isLender}) {
  return (
    <div>
        <NavMenu user={user} isLender={isLender}/>
          {children}
    </div>
  );
}

export default Layout