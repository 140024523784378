import React, { useEffect, useState } from 'react'

import { Container, Menu, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { ManageProfileURL } from '../utility/Global'
import { userSignout, userSignin, getUserDisplayName } from '../utility/Auth'
import { UserData } from 'oidc-react'

const NavMenu = ({user, isLender}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    setIsLoggedIn(user != null);
    if (user) {
      setUserName(getUserDisplayName(user.profile.name))
    }
  }, [user])

  return (
    <header style={{ marginBottom: '4em' }}>
      <Menu className='navigation-lightblue' borderless fixed='top'>
        <Container>
          <Menu.Item as={Link} to='/' header>
            {/**<Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />**/}
            CEMA
          </Menu.Item>
          <Menu.Item position='right' as={Link} to='/'>Dashboard</Menu.Item>
          {!isLender && (
            <>
              <Menu.Item as={Link} to='/attorneys'>Attorneys</Menu.Item>
              <Menu.Item as={Link} to='/loan-officers'>Loan Officers</Menu.Item>
            </>
          )}
          {isLoggedIn ? (
            <Dropdown text={userName} className='link item'>
              <Dropdown.Menu>
                <Dropdown.Item as='a' href={ManageProfileURL} target='_blank' >Profile</Dropdown.Item>
                <Dropdown.Item onClick={(e) => { userSignout() }}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item onClick={(e) => { userSignin() }}>Login</Menu.Item>
          )}
        </Container>
      </Menu>
    </header>
  );
}

export default NavMenu;