import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { BuilderPathURL } from '../utility/Global'
import { RenderLoadingMessage, RenderErrorMessage, WYSIWYGLabel, TransitionContainer } from '../utility/GlobalComponents'
import { makeGetRequest, makePostRequest } from '../utility/Services'

import { cemaStatus, cemaStatusColors } from '../utility/Data';

import { Container, Label, Button, Segment, Checkbox, Icon } from 'semantic-ui-react'

import Datatable from 'react-data-table-component'
import { formatDate } from '../utility/Helper';
import { customStyles, downloadCSV, FilterComponent } from '../utility/TableHelper';
import { getUser, userInRole } from '../utility/Auth';
import { useNavigate } from 'react-router-dom'

const csvColumns = {
  property: 'Property',
  borrower: 'Borrower',
  bank: 'Bank',
  loanOfficer: 'Loan Officer',
  status: 'Status',
  closingDate: 'Closing Date',
  cemaOrdered: 'CEMA Ordered',
  payoffOrdered: 'Payoff Orderd',
  goodThroughDate: 'Good Through Date'
}

export function Dashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isLender, setIsLender] = useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      name: ' ',
      width: '32px',
      selector: row => row.priority,
      sortable: true,
      cell: row => <>{row.isImmediate ? (<Icon name='exclamation' color='red'/> ) : null }{row.isPriority ? (<Icon name='star' color='yellow' /> ) : null}</>
   },
      {
          name: 'Loan Officer',
          selector: row => row.loanOfficer,
          sortable: true
      },
      {
          name: 'Borrower',
          selector: row => row.borrower,
          sortable: true,
          grow: 1.25,
          wrap: true
      },
      {
          name: 'Property',
          selector: row => row.property,
          sortable: true,
          grow: 1.5,
          format: row => <WYSIWYGLabel message={row.property} />
      },
      {
          name: 'Bank',
          selector: row => row.bank,
          sortable: true,
          grow: 1.25,
      },
      {
          name: 'CEMA Ordered',
          selector: row => row.cemaOrdered,
          sortable: true,
          center: true,
          format : row => (row.cemaOrdered ? `${formatDate((row.cemaOrdered))}` : '-')
      },
      {
          name: 'Payoff Ordered',
          selector: row => row.payoffOrdered,
          sortable: true,
          center: true,
          format : row => (row.payoffOrdered ? `${formatDate((row.payoffOrdered))}` : '-')
      },
      {
          name: 'Good Through Date',
          selector: row => row.goodThroughDate,
          sortable: true,
          center: true,
          format : row => (row.goodThroughDate ? `${formatDate((row.goodThroughDate))}` : '-')
      },
      {
          name: 'Status',
          selector: row => row.status,
          sortable: true,
          grow: 1,
          cell: row => <Label className='cema-status-label' style={{width: '100%', textAlign: 'center', fontSize: '11px'}} color={cemaStatusColors[row.status-1]}>{cemaStatus[row.status-1]}</Label>
      },
      {
          name: 'Closing Date',
          selector: row => row.closingDate,
          sortable: true,
          center: true,
          format : row => (row.closingDate ? `${formatDate((row.closingDate))}` : '-')
      },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await makeGetRequest(`${BuilderPathURL}/getcemagroups`);
        if (data)
          setData(data);
        setIsLoading(false);
        
        const user = await getUser();
        const isLender = userInRole(user.profile.role, 'CemaLender');
        setIsLender(isLender);

      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredItems(filterData(''));
}, [data, filterText])

  const createNewCemaGroup = async () => {
    try {
      const result = await makePostRequest(BuilderPathURL);
      if (result) {
        navigate(`/groups/${result}`);
      }
    } catch (error) {
      console.error("Failed to create new CEMA group:", error);
    }
  };

  const onRowClicked = (row) => {
    navigate(`/groups/${row.id}`);
  };

  const handleFilter = (e) => {
    const val = e.target.value;
    setFilterText(val);
    setFilteredItems(filterData(val));
  };

  const handleClear = () => {
    setFilterText('');
    setFilteredItems(filterData(''));
  };

  const handleAllToggle = () => {
    setShowAll(prevShowAll => !prevShowAll);
    setFilteredItems(filterData(filterText));
  };

  const filterData = (val) => {
    let tmpData = [...data];

    if (!showAll) {
      tmpData = tmpData.filter(item => item.status < cemaStatus.length - 1);
    }

    if (val) {
      return tmpData.filter(item =>
        item.loanOfficer?.toLowerCase().includes(val.toLowerCase()) ||
        item.borrower?.toLowerCase().includes(val.toLowerCase()) ||
        item.property?.toLowerCase().includes(val.toLowerCase()) ||
        item.bank?.toLowerCase().includes(val.toLowerCase()) ||
        item.cemaOrdered?.toLowerCase().includes(val.toLowerCase()) ||
        item.payoffOrdered?.toLowerCase().includes(val.toLowerCase()) ||
        item.goodThroughDate?.toLowerCase().includes(val.toLowerCase()) ||
        item.closingDate?.toLowerCase().includes(val.toLowerCase()) ||
        cemaStatus[item.status - 1]?.toLowerCase().startsWith(val.toLowerCase())
      );
    }

    return tmpData;
  };

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (field === 'priority') {
        const { isImmediate, isPriority } = row;
        return isImmediate && isPriority ? 3 : isImmediate ? 2 : isPriority ? 1 : 0;
      }
      return row[field]?.toString().toLowerCase() || row[field];
    };

    return _.orderBy(rows, handleField, direction);
  };

  const renderDashboard = () => (
    <Datatable
      noHeader
      title='Dashboard'
      persistTableHead
      columns={columns}
      data={filteredItems}
      defaultSortField='loanOfficer'
      highlightOnHover
      pointerOnHover
      customStyles={customStyles}
      fixedHeader
      fixedHeaderScrollHeight='75vh'
      sortFunction={customSort}
      striped
      onRowClicked={onRowClicked}
      responsive
      subHeader
      subHeaderAlign='left'
      subHeaderComponent={(
        <Segment basic style={{ width: '100%' }}>
          <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
          <div style={{ marginLeft: '2em', display: 'inline' }}>
            <em>Active</em>
            <Checkbox
              style={{ marginLeft: '1em', marginRight: '1em', verticalAlign: 'text-bottom' }}
              toggle
              checked={showAll}
              onChange={handleAllToggle}
            />
            <em>All</em>
          </div>
          {!isLender && <Button floated='right' content='New Group' onClick={createNewCemaGroup} positive />}
          <Button floated='right' content='Export' onClick={() => downloadCSV(filteredItems, 'dashboard', csvColumns)} />
        </Segment>
      )}
    />
  );  
  return (
    <TransitionContainer in={!isLoading} timeout={500}>
      <div className='Dashboard' style={{ padding: '0 15em' }}>
          {isLoading ? (
            RenderLoadingMessage()
          ) : data.length === null ? (
            RenderErrorMessage()
          ) : (
            renderDashboard()
          )}
        </div>
    </TransitionContainer>
  );
}