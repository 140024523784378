import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import Layout from './components/Layout';
import GroupWrapper from './components/Group';
import {Dashboard} from './components/Dashboard';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { getUser, setUserManager, userAuthorized, userInRole } from './utility/Auth'
import { Attorneys } from './components/Attorneys';
import { LoanOfficers } from './components/pages/LoanOfficers';
import { useAuth } from 'oidc-react';

library.add(fab, faArrowRight)

const routes = [{
  path: '/',
  component: Dashboard,
}, {
  path: '/Groups',
  component: GroupWrapper,
}, {
  path: '/Groups/:id',
  component: GroupWrapper,
}, {
  path: '/Attorneys',
  component: Attorneys
}, {
  path: '/Loan-Officers',
  component: LoanOfficers
}];

const NotAuthorized = () => {
  return (<em>Access Denied</em>)
}

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    if ('caches' in window) {
      caches.open('path')
        .then(cache => cache.match('/'))
        .then(result => result && result.json())
        .then(path => {
          if (path) {
            navigate(path);
          } else {
            console.error("No path found in cache");
          }
        })
        .catch(error => {
          console.error("Failed to fetch from cache", error);
        });
    }
  }, [navigate]);

  return null;
}

function App() {
  const [isAuthorized, setIsAuthorized] = useState(null)
  const [user, setUser] = useState(null)
  const [isLender, setIsLender] = useState(false);
  const auth = useAuth()  

  useEffect(() => {
    if (user) {
      const isLender = userInRole(user.profile.role, 'CemaLender');
      setIsLender(isLender);
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      if (auth && !auth.isLoading) {
        try {
          setUserManager(auth.userManager);
          const user = await getUser();
          
          setUser(user);
          if (user?.profile) {
            setIsAuthorized(userAuthorized(user.profile.role));
          } else {
            setIsAuthorized(false);
          }
        } catch (error) {
          console.error("Failed to fetch user data or set userManager:", error);
          setIsAuthorized(false);
        }
      }
    }

    fetchData();
  }, [auth]);

  const routeComponents = routes.map(({ path, component: Component }, key) => (
    <Route exact path={path} element={<Component />} key={key} />
  ));
  
  return (
    <>
      {auth.isLoading ? (
        <em>Loading...</em>
      ) : (
        <Layout user={user} isLender={isLender}>
          {isAuthorized ? (
            <Routes>
              <Route path="/callback" element={<Callback />} />
              {routeComponents}
            </Routes>
          ) : (
            <NotAuthorized />
          )}
        </Layout>
      )}
    </>
  );

}
export default App;