import React, { useState } from 'react';
import { Button, Modal, Form, Checkbox } from 'semantic-ui-react'
import { LoanOfficerPathURL } from '../../utility/Global';
import { makePutRequest } from '../../utility/Services';

const CreateLoanOfficer = ({ loanOfficerToUse }) => {
    const [loanOfficer, setLoanOfficer] = useState(loanOfficerToUse)
    const [isOpened, setIsOpened] = useState(true)

    const onSave = async () => {
        let result = await makePutRequest(`${LoanOfficerPathURL}/${loanOfficer.id}`, loanOfficer)

        if (result) {
            setIsOpened(false)
        }
    }

    return (
        <Modal
            size='mini'
            open={isOpened}
            onClose={onSave}
        >
            <Modal.Header>New Loan Officer</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Name</label>
                        <input placeholder='Name' defaultValue={loanOfficer?.name} onChange={(e) => setLoanOfficer(loanOfficer => ({
                            ...loanOfficer,
                            name: e.target.value
                        }))} />
                    </Form.Field>
                    <Form.Field>
                        <label>Company</label>
                        <input placeholder='Company' defaultValue={loanOfficer?.company} onChange={(e) => setLoanOfficer(loanOfficer => ({
                            ...loanOfficer,
                            company: e.target.value
                        }))} />
                    </Form.Field>
                    <Form.Group inline>
                        <Form.Field
                            control={Checkbox}
                            label='Broker?'
                            checked={loanOfficer?.isBroker} onChange={(e) => setLoanOfficer(loanOfficer => ({
                                ...loanOfficer,
                                isBroker: !loanOfficer.isBroker
                            }))}
                        />
                        <Form.Field
                            control={Checkbox}
                            label='Referred by UWM?'
                            checked={loanOfficer?.isReferredByUWM} onChange={(e) => setLoanOfficer(loanOfficer => ({
                                ...loanOfficer,
                                isReferredByUWM: !loanOfficer.isReferredByUWM
                            }))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={onSave}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export {
    CreateLoanOfficer
}