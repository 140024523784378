import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import {storedBankOptions} from '../../utility/Data'

export default class BankInput extends Component {
  state = {
    searchQuery: ''
   };

    handleSearchChange = (e, { searchQuery }) => { 
        // const setOpen = searchQuery.length >= 1

        this.setState({ searchQuery})
        //this.props.onChange(null, {name: this.props.name, value:searchQuery})
    }

    handleOnChange = (e, { value }) => {
      let v = value ? value : null
      
      this.props.onChange(null, {name: this.props.name, value: v})

      this.setState({searchQuery: storedBankOptions.find(_ => _.value === v)?.name})
        //this.props.onChange(null, {name: this.props.name, value})
    }

    render() {
      const { searchQuery } = this.state

      const { name, placeholder, value } = this.props

      const selected = storedBankOptions.find(_ => _.text === value)?.value

      return (
        <Dropdown
            key={value}
            className='mini'
            onChange={this.handleOnChange}
            onSearchChange={this.handleSearchChange}
            //onBlur={this.handleOnChange}
            disabled={this.props.disabled}
            clearable
            options={storedBankOptions}
            name={name}
            placeholder={placeholder}
            search
            searchQuery={searchQuery}
            selection
            defaultValue={selected}
            noResultsMessage={null}
            selectOnBlur={true}
            selectOnNavigation={false}
            minCharacters={2}
        />
      )
    }
  }