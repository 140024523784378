import React from 'react';

import {StopType, isMortgageType, isCemaType, isMTransferType, isATransferType} from '../../utility/Data'
import {textForMers, formatDate, formattedStopLabel, getConsolidatedStopLabels, formatNumber, getLetterFromIndex} from '../../utility/Helper'

import {Card, Button, Icon} from 'semantic-ui-react'
  
function cardExtraTitle(stop) {
    switch (stop.type) {
        case StopType.Cema: {
        return stop.isAmended ? '(Amended)' : `(Consolidating: ${formattedStopLabel(getConsolidatedStopLabels(stop.consolidationFrom)+(stop.isNoNewMoney ? '' : `,${stop.stop}`))})`
    }
    case StopType.Mortgage:
        return stop.isSubLien ? '(Subordinate Lien)' : stop.isAmended ? '(Amended)' : ''
    case StopType.ATransfer:
    case StopType.MTransfer:
    default:
        return ''
}
}

const StopCardMortgageContent = ({info}) => (
    <section>
      <span><strong>Mortgagor: </strong></span><span>{info.mortgagor}</span>
      <br/>
      <span><strong>Mortgagee: </strong></span><span>{textForMers(info.isMers)}{info.mortgagee}</span>
      <br/>
      <span><strong>Amount: </strong></span><span>{formatNumber(info.amount)}</span>
      <br/>
      <span><strong>Date: </strong></span><span>{formatDate(info.date)}</span>
    </section>
)

const StopCardCemaContent = ({info}) => (
  <section>
    <span><strong>Mortgagor: </strong></span><span>{info.mortgagor}</span>
    <br/>
    <span><strong>Mortgagee: </strong></span><span>{textForMers(info.isMers)}{info.mortgagee}</span>
    <br/>
    { !info.isNoNewMoney ? (
      [<span>
        <strong>Gap Amount: </strong>
      </span>,
      <span>{formatNumber(info.amount)}</span>,
      <br/>]
      ) : null}
    <span><strong>Consolidated Amount: </strong></span><span>{formatNumber(info.consolidatedAmount)}</span>
    <br/>
      { !info.isNoNewMoney ? (
      [<span>
        <strong>New Money Date: </strong>
      </span>,
      <span>{formatDate(info.date)}</span>,
      <br/>]
      ) : null}
    <span><strong>CEMA Date: </strong></span><span>{formatDate(info.cemaDate)}</span>
  </section>
)

const StopCardATransferContent = ({info}) => (
  <section>
    <span><strong>Assignee: </strong></span><span>{textForMers(info.isMers)}{info.assignee}</span>
    <br/>
    <span><strong>Date: </strong></span><span>{formatDate(info.date)}</span>
  </section>
)

const StopCardMTransferContent = ({info}) => (
  <section>
    <span><strong>Mortgagee: </strong></span><span>{textForMers(info.isMers)}{info.assignee}</span>
    <br/>
    <span><strong>Date: </strong></span><span>{formatDate(info.date)}</span>
  </section>
)

const StopCardContent = ({info}) => (
  <Card.Content>
    { isMortgageType(info.type) ? (
      <StopCardMortgageContent info={info} />
    ) : isCemaType(info.type) ? (
      <StopCardCemaContent info={info} />
    ) : isATransferType(info.type) ? (
      <StopCardATransferContent info={info} />
    ) : isMTransferType(info.type) ? (
      <StopCardMTransferContent info={info} />
    ) : null}
  </Card.Content>
)

export const StopCard = ({stopInfo, onEdit, onDelete, isEditing, isCurrentEditing, isFirst, disabled = false}) => (
  <Card className={isCurrentEditing ? 'cema-card isEditing' : 'cema-card'}>
    <Card.Content className='cema-card-header'>
      <Card.Header>Mortgage {stopInfo.stop}{stopInfo.transferStop ? getLetterFromIndex(stopInfo.transferStop) : stopInfo.amendedStop ? '.' + stopInfo.amendedStop : ''}
      { !isEditing ? (
        <Button.Group className='inherit' floated='right' size='small' >
        <Button disabled={disabled} icon onClick={onEdit}>
          <Icon name='edit outline' />
        </Button>
        {!isFirst ? (
          <Button disabled={disabled} icon onClick={onDelete}>
          <Icon name='trash alternate outline' />
        </Button>
        ) : null}
      </Button.Group>
      ) : null }
      </Card.Header>
    </Card.Content>
    <Card.Content className='cema-card-extra' extra>
      {stopInfo.type} {cardExtraTitle(stopInfo)}
    </Card.Content>
    <StopCardContent info={stopInfo} />
  </Card>
)