import React from 'react'
import {Button, Input} from 'semantic-ui-react'
import { cemaStatus } from './Data';

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(data, columns) {
    let result;
  
    const columnDelimiter = ',';
      const lineDelimiter = '\n';
      let keys = Object.keys(columns);
  
      result = '';
      result += Object.values(columns).join(columnDelimiter);
      result += lineDelimiter;
    
      data.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
          if (ctr > 0) result += columnDelimiter;
    
          let val = item[key] ?? ''
  
          if (key === 'status') {
            val = cemaStatus[val-1]
          }
  
          result += `"${val}"`;
          
          ctr++;
        });
        result += lineDelimiter;
      });
  
    return result;
  }
  
  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  export function downloadCSV(data, file, columns) {
  if (data === null || data.length === 0) {
    return
  }
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV([...data], columns);
    if (csv === null) return;
  
    const filename = `${file}.csv`;
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

export const customStyles = {
    subHeader: {
      style: {
        padding: '0'
      }
    },
    headRow: {
      style: {
        height: '48px',
        backgroundColor: 'lightblue'
      }
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '11px',
        fontWeight: 'bolder',
        textTransform: 'uppercase'
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        outline: '1px solid #FFFFFF',
      },
      style: {
          fontSize: '11px',
      }
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  };

export const FilterComponent = ({ filterText, onFilter, onClear, minWidth = '48px' }) => (
    <Input label={<Button style={{minWidth: minWidth}} type="button" onClick={onClear} icon='close'></Button>} 
           labelPosition='right'
           size='small'
           type="text" placeholder="Search..."  value={filterText} onChange={onFilter} />
  )