import React, {Component} from 'react';

import {isCemaType, isMortgageType} from '../../utility/Data'
import {textForAmount, textForCopy, textForDate, textForMers, isEmpty} from '../../utility/Helper'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {List, Checkbox, Grid, Divider} from 'semantic-ui-react'
import { BuilderPathURL } from '../../utility/Global'
import { makePutRequest } from '../../utility/Services'

const sectionTypes = [1,3,2,4]

function checklistTitleLabel(key, amendedStop, section) {
  switch (section) { 
    case 1:
      return `Mtg ${key}`
    case 2:
      return `Mtg ${key} Cons.`
    case 3:
      return `Mtg ${key} Amnd. ${amendedStop}`
    case 4:
      return `Mtg ${key} C. Amnd. ${amendedStop}`
  }
}

function isSectionNewMoney(section) {
  return section.notes.length > 1 && section.copies.length > 1
}

const CoverPageNoteGroup = ({note, allonges}) => (
  <List.Item>
    <strong>{isCemaType(note.section) ? 'Consolidated ' : ''}Note</strong> for {note.mortgagor}&nbsp;&nbsp;<FontAwesomeIcon icon="arrow-right"/>&nbsp;&nbsp;{note.mortgagee}{textForDate(note.date)}{textForAmount(note.amount)}
    {allonges.length > 0 ? (
      <List.List className='cover-page-list-allonges'>
      {allonges.map((allonge, index) => {
        return <CoverPageAllonge key={index} allonge={allonge} />
      })}
    </List.List>
    ) : null }
  </List.Item>
)

const CoverPageCopy = ({copy}) => (
  <List.Item>
    { copy.sectionType === 1 || copy.sectionType === 3 ? (
      <CoverPageMortgageCopy copy={copy} />
    ) : (
      <CoverPageCEMACopy copy={copy} />
    )}
  </List.Item>
)

const CoverPageMortgageCopy = ({copy}) => (
  <span><strong>{textForCopy(copy.isCopy)}{copy.section}</strong>{textForAmount(copy.amount)}</span>
)

const CoverPageCEMACopy = ({copy}) => (
  <span><strong>{textForCopy(copy.isCopy)}{copy.section}</strong> from {copy.mortgagor}&nbsp;&nbsp;<FontAwesomeIcon icon='arrow-right'/>&nbsp;&nbsp;{copy.mortgagee}{textForDate(copy.date)}{textForAmount(copy.amount)}</span>
)

const CoverPageAllonge = ({allonge}) => (
  <List.Item><strong>Allonge</strong> from {allonge.personOne}&nbsp;&nbsp;<FontAwesomeIcon icon='arrow-right'/>&nbsp;&nbsp;{allonge.personTwo}</List.Item>
)

const CoverPageAssignment = ({assignment}) => (
  <List.Item><strong>{textForCopy(assignment.isCopy)}Assignment</strong> from {textForMers(assignment.fromMers)}{assignment.personOne}&nbsp;&nbsp;<FontAwesomeIcon icon='arrow-right'/>&nbsp;&nbsp;{textForMers(assignment.isMers)}{assignment.personTwo}{textForDate(assignment.date)}</List.Item>
)

const CoverPageSection = ({note, copy, allonges}) => (
  <List bulleted className='cover-page-list'>
    {note ? (
      <CoverPageNoteGroup note={note} allonges={allonges} />
    ) : null}
    <CoverPageCopy copy={copy} />
  </List>
)

const CoverPageAssignmentsSection = ({assignments}) => (
  <List bulleted className='cover-page-list'>
    {assignments.map((assignment, index) => {
      return <CoverPageAssignment key={index} assignment={assignment} />
    })}
  </List>
)


  export default class CoverPage extends Component {
    itemsToUpdate = []
    updateTimer = null
    
    updateChecklist = async (event, {scan, original, item, checked}) => {
      event.preventDefault()

      if (this.updateTimer !== null) {
        clearTimeout(this.updateTimer)
      }
  
      const {cemaGroupId, closed} = this.props

      if (closed) {
        return
      }
  
  //    var model = {...item}
  
      if (scan) {
        //model.isScan = checked
        item.isScan = checked
      } else {
        //model.isOriginal = checked
        item.isOriginal = checked
      }

      this.itemsToUpdate.push(item)

      this.forceUpdate()

      this.updateTimer = setTimeout(async () => {
        const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupId}/updatechecklist/`, this.itemsToUpdate)

        if (result && result.message == "Success") {
          // note.isScan = result.isScan
          // note.isOriginal = result.isOriginal
        } else {
          // Might need to change back to previous
        }

        this.itemsToUpdate = []
      }, 1000)
    }

    CoverPageNoteCheckboxGroup = (note, allonges, disabled = false) => (
      <List.Item>
        <Checkbox disabled={disabled} className='cb-scan' scan='scan' item={note} onChange={this.updateChecklist.bind(this)} checked={note?.isScan}/>&nbsp;&nbsp;<Checkbox disabled={disabled} className='cb-original' original='original' item={note} onChange={this.updateChecklist.bind(this)} checked={note?.isOriginal}/>
        {allonges.length > 0 ? (
          <List.List className='cover-page-list-allonges-checkboxes'>
          {allonges.map((allonge, index) => {
            return <List.Item key={allonge.id}><Checkbox disabled={disabled} className='cb-scan' scan='scan' item={allonge} onChange={this.updateChecklist.bind(this)} checked={allonge.isScan}/>&nbsp;&nbsp;<Checkbox disabled={disabled} className='cb-original' original='original' item={allonge} onChange={this.updateChecklist.bind(this)} checked={allonge.isOriginal}/></List.Item>
          })}
        </List.List>
        ) : null }
      </List.Item>
    )

    CoverPageCheckboxSection = (note, copy, allonges, disabled = false) => (
      <List className='cover-page-list'>
        {note ? (
          this.CoverPageNoteCheckboxGroup(note, allonges, disabled)
        ) : null}
        <List.Item><Checkbox disabled={disabled} className='cb-scan' scan='scan' item={copy} onChange={this.updateChecklist.bind(this)} checked={copy?.isScan}/>&nbsp;&nbsp;<Checkbox disabled={disabled} className='cb-original' original='original' item={copy} onChange={this.updateChecklist.bind(this)} checked={copy?.isOriginal}/></List.Item>  {/**Copy */}
      </List>
    )
    
    CoverPageAssignmentsCheckboxSection = (assignments, disabled = false) => (
      <List className='cover-page-list'>
        {assignments.map((assignment, index) => {
          return <List.Item key={assignment.id}><Checkbox disabled={disabled} className='cb-scan' scan='scan' item={assignment} onChange={this.updateChecklist.bind(this)} checked={assignment.isScan}/>&nbsp;&nbsp;<Checkbox disabled={disabled} className='cb-original' original='original' item={assignment} onChange={this.updateChecklist.bind(this)} checked={assignment.isOriginal}/></List.Item>
        })}
      </List>
    )
    

    render() {
      const {checklist} = this.props
      const shouldRender = !isEmpty(checklist) && Object.entries(checklist).length > 0
      return (
        <section>
          {shouldRender ? (
            <Grid centered style={{marginBottom: '-2rem'}}>
            <Grid.Row>
                <Grid.Column width={2}>
      
                </Grid.Column>
                <Grid.Column style={{paddingLeft: '0.5rem', fontSize: '11.5px'}} textAlign='center' width={1}>
                  <strong style={{color: 'blue'}}>Scn</strong>&nbsp;&nbsp;<strong style={{color: 'green'}}>Org</strong>
                </Grid.Column>
                <Grid.Column width={13}>
                </Grid.Column>
              </Grid.Row>
            </Grid>
        ) : null }
        
        { shouldRender && Object.entries(checklist).map(([key, section]) => {

           return <Grid centered key={key} className='cover-page-section'>
                          
              {sectionTypes.map((element, index) => {
                var sectionNotes = section.notes.filter(_ => _.sectionType === element)
                var sectionCopies = section.copies.filter(_ => _.sectionType === element)
                var sectionAllonges = section.allonges.filter(_ => _.sectionType === element)

                var largerCountArray = sectionNotes.length >= sectionCopies.length ? sectionNotes : sectionCopies

                largerCountArray.sort((a, b) => {
                  if (!a.amendedStop) {
                    return 1
                  } else {
                    if (a.amendedStop > b.amendedStop) return 1
                    if (a.amendedStop < b.amendedStop) return -1
                  }
                })
                return (
                  <>
                  {largerCountArray.map((item, index) => {
                    var aStop = item.amendedStop
                    var note = sectionNotes.filter(_ => _.amendedStop === aStop)[0] ?? null
                    var copy = sectionCopies.filter(_ => _.amendedStop === aStop)[0] ?? null
  
                    var allonges = sectionAllonges.filter(_ => _.amendedStop === aStop) ?? []
  
                    var sectionType = note ? note.sectionType : copy ? copy.sectionType : 0
                    var amendedStop = note ? note.amendedStop : copy ? copy.amendedStop : 0
  
                    return (
                      <> 
                      {note || copy ? (
                      <Grid.Row>
                        <Grid.Column textAlign='left' width={2}>
                          <span className='cover-page-header'>
                          {checklistTitleLabel(key, amendedStop, sectionType)}
                          </span>
                        </Grid.Column>
                        <>
                        <Grid.Column width={1}>  
                        {this.CoverPageCheckboxSection(note, copy, allonges, this.props.disabled)}
                        </Grid.Column>
                        <Grid.Column width={13}>
                          <CoverPageSection note={note} copy={copy} allonges={allonges} disabled={this.props.disabled} /> 
                        </Grid.Column>
                        </>
                      </Grid.Row>
                      ) : null }
                      <Divider style={{marginTop: 0}} hidden />
                      </>
                    )
                  })}
                  </>
                  )
                })
              }
              
              {section.assignments.length > 0 ? (
                <>
                <Grid.Row>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={1}>
                        {this.CoverPageAssignmentsCheckboxSection(section.assignments, this.props.disabled)}
                  </Grid.Column>
                  <Grid.Column width={13}>
                        <CoverPageAssignmentsSection assignments={section.assignments} disabled={this.props.disabled} />
                  </Grid.Column>
                </Grid.Row>
                <Divider />  
                </>
                ) : null }
                
            </Grid>
         })}

        {shouldRender ? (
           <Divider />
         ) : null }
  
        </section>
      )
    }
  }
