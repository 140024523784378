import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import DataTable from 'react-data-table-component';
import { LoanOfficerPathURL } from '../../utility/Global';
import { RenderErrorMessage, RenderLoadingMessage, TransitionContainer } from '../../utility/GlobalComponents';
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest } from '../../utility/Services';
import {Segment, Button, Icon, Input, Checkbox} from 'semantic-ui-react'
import { customStyles, downloadCSV, FilterComponent } from '../../utility/TableHelper';

const csvColumns = {
    name: 'Name',
    company: 'Company'
  }

export function LoanOfficers() {
    const [loanOfficers, setLoanOfficers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [filterText, setFilterText] = useState('')
    const [filteredItems, setFilteredItems] = useState([])
    const [editedOfficer, setEditedOfficer] = useState(null)
    
    useEffect(() => {
        async function fetchData() {
          try {
            const data = await makeGetRequest(`${LoanOfficerPathURL}`)
            if (data)
                setLoanOfficers(data)
            setIsLoading(false)
    
          } catch (error) {
            console.error("Failed to fetch data:", error);
          } finally {
            setIsLoading(false);
          }
        }
        fetchData()
      }, [])

    useEffect(() => {
        setFilter(filterText)
    }, [loanOfficers, filterText])

    const handleClear = () => {
        setFilterText('')
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            cell: row => editedOfficer && editedOfficer.id === row.id ? <Input defaultValue={editedOfficer.name} onBlur={(e) => setEditedOfficer(editedOfficer => ({
                ...editedOfficer,
                name: e.target.value
            }))} /> : row.name
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: row => editedOfficer && editedOfficer.id === row.id ? <Input defaultValue={editedOfficer.company} onBlur={(e) => setEditedOfficer(editedOfficer => ({
                ...editedOfficer,
                company: e.target.value
            }))} /> : row.company
        },
        {
            name: 'Is Broker',
            selector: row => row.isBroker,
            sortable: true,
            cell: row => editedOfficer && editedOfficer.id === row.id ? <Checkbox checked={editedOfficer.isBroker} onChange={(e) => setEditedOfficer(editedOfficer => ({
                ...editedOfficer,
                isBroker: !editedOfficer.isBroker
            }))} /> : row.isBroker && 'Yes'
        },
        {
            name: 'Referred by UWM',
            selector: row => row.isReferredByUWM,
            sortable: true,
            cell: row => editedOfficer && editedOfficer.id === row.id ? <Checkbox checked={editedOfficer.isReferredByUWM} onChange={(e) => setEditedOfficer(editedOfficer => ({
                ...editedOfficer,
                isReferredByUWM: !editedOfficer.isReferredByUWM
            }))} /> : row.isReferredByUWM && 'Yes'
        },
        {
            name: '',
            right: true,
            width: '150px',
            cell: row => <>
                <Button.Group basic className='no-border'>
                    {editedOfficer && editedOfficer?.id === row?.id ? (
                        <Button icon onClick={() => onEditOfficer(row.id)}>
                            <Icon name='save'></Icon>
                        </Button>
                    ) : (
                        <Button icon onClick={() => onRowEdit(row.id)}>
                            <Icon name='edit'></Icon>
                        </Button>
                    )}
                    <Button icon onClick={() => onDeleteOfficer(row.id)}>
                        <Icon name='trash'></Icon>
                    </Button>
                </Button.Group></>
        }
    ]

    const onAddLoanOfficer = () => {
        let officer = {id: 0, name: '', company: ''}
        setLoanOfficers(loanOfficers => ([
            ...loanOfficers, officer
        ]))
        setEditedOfficer(officer)
    }

    const onRowEdit = (id) => {
        const loanOfficer = loanOfficers.find(_ => _.id === id)

        setEditedOfficer({...loanOfficer})
    }

    const onEditOfficer = async (id) => {        
        if (editedOfficer) {
            const loanOfficer = loanOfficers.find(_ => _.id === id)
            if (JSON.stringify(editedOfficer) === JSON.stringify(loanOfficer)) {
                setEditedOfficer(null)
                return
            } 
            const result = id === 0 ? await makePostRequest(LoanOfficerPathURL, editedOfficer) : await makePutRequest(`${LoanOfficerPathURL}/${id}`, editedOfficer)

            if (result.level === 1) {
                
                if (loanOfficer) {
                    let editedItem = result.result
                    loanOfficer.name = editedItem.name
                    loanOfficer.company = editedItem.company
                    loanOfficer.isBroker = editedItem.isBroker
                    loanOfficer.isReferredByUWM = editedItem.isReferredByUWM

                    setLoanOfficers(loanOfficers => loanOfficers)
                    setEditedOfficer(null)
                }   
            } else {
                alert(result.message)
            }
        }
    }

    const onDeleteOfficer = async (id) => {
        if (id === 0) {
            setLoanOfficers(loanOfficers => ([
                ...loanOfficers.filter(_ => _.id !== id)
            ]))
            return
        }
        const result = await makeDeleteRequest(`${LoanOfficerPathURL}/${id}`)

        if (result.level === 1) {
            let deletedItem = result.result
            setLoanOfficers(loanOfficers => ([
                ...loanOfficers.filter(_ => _.id !== deletedItem.id)
            ]))
        } else {
            alert(result.message)
        }
    }

    const setFilter = (val) => {
        let tmpData = [...loanOfficers]

        let tmpFilteredItems = tmpData
        if (val !== '') {
            tmpFilteredItems = tmpData.filter(item =>
                (item.name?.toLowerCase().includes(val.toLowerCase()) ||
                item.company?.toLowerCase().includes(val.toLowerCase()))
            )
        }

        setFilteredItems(tmpFilteredItems)
    }

    const handleFilter = (e) => {
        const val = e.target.value
        if (val === '') {
          handleClear()
          return
        }
        setFilterText(val)
      }

    const customSort = (rows, field, direction) => {
        const handleField = row => {
        if (row[field]) {
          
          if (typeof row[field] === 'string') {
             return row[field].toLowerCase();
          }
        }
    
        return row[field];
      };
    
      return _.orderBy(rows, handleField, direction)
    }

    const renderDashboard = () => {
            return (
                <DataTable
                noHeader={true}
                title='Loan Officers'
                persistTableHead
                columns={columns}
                data={filteredItems}
                defaultSortField='name'
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight='75vh'
                sortFunction={customSort}
                striped
                responsive
                subHeaderAlign='left'
                subHeader
                subHeaderComponent={(
                  <Segment basic style={{width: '100%'}}>
                  <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
                  <Button positive floated='right' type='button' content='New Officer' onClick={onAddLoanOfficer} />
                  <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(filteredItems, 'loan-officers', csvColumns)}}/>
                  </Segment>
                )}
                />
            )
      }

      const contents = () => {
         return isLoading ? RenderLoadingMessage() : loanOfficers === null ? RenderErrorMessage() : renderDashboard()
      }

      return (
        <TransitionContainer className='LoanOfficers' style={{padding: '0 15em'}}>
        {contents()}
        </TransitionContainer>
      )
}