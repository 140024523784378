import React from 'react'

import {Form} from 'semantic-ui-react'
import { isMortgageType, isCemaType, isTransferType, StopType } from '../../utility/Data';
import { isEmpty } from '../../utility/Helper';
import SelectInput from './SelectInput';

export const AmendedInput = ({options, stop, stopError, stopType, onChange}) => (
    <Form.Group grouped>
        <Form.Field className='cema-form-field no-error' error={stopError.isAmended} >
            <SelectInput
            label='Amending'
            multiple={false}
            options={options}
            placeholder='Stop'
            //defaultValue={transferOptions.default}
            value={isMortgageType(stopType) ? (!isEmpty(stop.amendedFrom) ? (stop.amendedFrom.type + '.' + stop.amendedFrom.id) : '') : '' }
            onChange={onChange}
            disabled={isTransferType(stopType) || stopType === StopType.None} />
        </Form.Field>
    </Form.Group>
)

export const ConsolidationInput = ({options, stop, stopError, stopType, onChange}) => (
    <Form.Group grouped>
        <Form.Field className='cema-form-field no-error' error={stopError?.isCema || stopError?.consolidationFrom}>
            <SelectInput
            label='Consolidating'
            multiple={true}
            options={options}
            placeholder='Stop'
            //defaultValue={consolidationOptions.default}
            value={isCemaType(stopType) ? stop?.consolidationFrom : []}
            onChange={onChange}
            disabled={isTransferType(stopType) || stopType === StopType.None}/>
        </Form.Field>
    </Form.Group>
)