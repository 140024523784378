import React, { Component, useEffect, useState } from 'react'
import _ from 'lodash'
import memoize from 'memoize-one';

import { AttorneyPathURL } from '../utility/Global'
import { RenderLoadingMessage, RenderErrorMessage, WYSIWYGLabel, TransitionContainer } from '../utility/GlobalComponents'
import { makeGetRequest } from '../utility/Services'

import { Button, Segment } from 'semantic-ui-react'

import Datatable from 'react-data-table-component'
import { customStyles, downloadCSV, FilterComponent } from '../utility/TableHelper';

const csvColumns = {
  name: 'Name',
  nickname: 'Nickname',
  address: 'Address',
  phone: 'Phone',
  fax: 'Fax',
  email: 'Email',
  url: 'URL',
}

export function Attorneys() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const columns = [
      {
          name: 'Name',
          selector: row => row.name,
          sortable: true
      },
      {
          name: 'Nickname',
          selector: row => row.nickname,
          sortable: true
      },
      {
          name: 'Address',
          selector: row => row.address,
          sortable: true,
          grow: 1,
          cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.address} /></div>
      },
      {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.phone} /></div>
      },
      {
        name: 'Fax',
        selector: row => row.fax,
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.fax} /></div>
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.email} /></div>
    },
    {
        name: 'URL',
        selector: row => row.url,
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.url} /></div>
    },
    {
        name: 'Banks',
        selector: row => row.banks,
        cell: row => <div className='contact-bank-cell'>
            {row.banks?.map((item, index) => {
                return <span key={index}>{item?.name}</span>
            })}
        </div>
        
    }
  ]

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await makeGetRequest(`${AttorneyPathURL}`)
        if (data)
          setData(data);
        setIsLoading(false);

      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setFilteredItems(filterData(''));
}, [data, filterText])

  const handleClear = () => {
    setFilterText('');
    setFilteredItems(filterData(''));
  };

  const handleFilter = (e) => {
    const val = e.target.value;
    setFilterText(val);
    setFilteredItems(filterData(val));
  };

  const filterData = (val) => {
    let tmpData = [...data];

    if (val) {
      return tmpData.filter(item =>
        (item.name?.toLowerCase().includes(val.toLowerCase()) ||
        item.nickname?.toLowerCase().includes(val.toLowerCase()) ||
        item.address?.toLowerCase().includes(val.toLowerCase()) ||
        item.phone?.toLowerCase().includes(val.toLowerCase()) ||
        item.fax?.toLowerCase().includes(val.toLowerCase()) ||
        item.email?.toLowerCase().includes(val.toLowerCase()))
      );
    }

    return tmpData;
  };

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        if (typeof row[field] === 'string') {
          return row[field].toLowerCase();
       }
      }
      return row[field];
    };

    return _.orderBy(rows, handleField, direction);
  };

  const renderDashboard = () => (
    <Datatable
      noHeader={true}
      title='Attorneys'
      persistTableHead
      columns={columns}
      data={filteredItems}
      defaultSortField='nickname'
      highlightOnHover
      pointerOnHover
      customStyles={customStyles}
      fixedHeader
      fixedHeaderScrollHeight='75vh'
      sortFunction={customSort}
      striped
      responsive
      subHeaderAlign='left'
      subHeader
      subHeaderComponent={(
        <Segment basic style={{width: '100%'}}>
        <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(filteredItems, 'attorneys', csvColumns)}}/>
        </Segment>
      )}
    />
  );  

  return (
    <TransitionContainer in={!isLoading} timeout={500}>
      <div className='Attorneys' style={{ padding: '0 15em' }}>
          {isLoading ? (
            RenderLoadingMessage()
          ) : data.length === null ? (
            RenderErrorMessage()
          ) : (
            renderDashboard()
          )}
        </div>
    </TransitionContainer>
  );
}
